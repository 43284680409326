<template>
  <div class="wb">
    <div class="wb-contents">
      <slot />
    </div>

    <transition name="layout-mask">
      <div v-if="searchActive" class="layout-mask p-component-overlay" />
    </transition>
  </div>
  <Toast></Toast>
  <ConfirmDialog></ConfirmDialog>
  <GlobalDialog />
</template>
